import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// eslint-disable-next-line react/display-name
function MarcoteCaseOne() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "marcote-web.PNG" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return <Img fluid={data.file.childImageSharp.fluid} className="h-100 min-vh-80 cover" alt="" />;
}
export default MarcoteCaseOne;
